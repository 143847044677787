import { HMSRoomProvider } from "@100mslive/react-sdk";
import Stream from "./Stream";

function App() {
  return (
    <HMSRoomProvider>
      <Stream />
    </HMSRoomProvider>
  );
}

export default App;
